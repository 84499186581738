import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { BsSearch } from "@react-icons/all-files/bs/BsSearch";

const smooth = "transition-all ease-in-out duration-300";

function SearchBox({ refine }) {
  return (
    <div
      action=""
      role="search"
      className={`${smooth} group shadow-md drop-shadow hover:drop-shadow-lg hover:shadow-md bg-white px-2 w-[100%] py-2 rounded-full flex justify-center items-center`}
    >
      <input
        id="algolia_search"
        type="search"
        onChange={(e) => refine(e.currentTarget.value)}
        placeholder=""
        className={`${smooth} group border-b-2 bg-white bg-opacity-0 mx-4 my-1 w-full outline-0 font-bodylao `}
      />
      <div
        className={`${smooth} text-2xl pr-3 text-stone-400 group-hover:text-stone-700`}
      >
        <BsSearch className={``} />
      </div>
    </div>
  );
}
export default connectSearchBox(SearchBox);
