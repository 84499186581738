import React from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import CustomSearchBox from "./CustomSearchBox";
import CustomHits from "./CustomHits";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const Search = () => {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="Posts">
        <CustomSearchBox />
        <CustomHits />
      </InstantSearch>
    </div>
  );
};

export default Search;
