import React from "react";
import { connectStateResults } from "react-instantsearch-dom";
import slugify from "slugify";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const smooth = "transition-all ease-in-out duration-300";

function CustomHits({ searchState, searchResults }) {
  const validQuery = searchState.query?.length >= 3;

  return (
    <div
      className={`${smooth} ${
        validQuery
          ? "visible opacity-100 h-auto pt-8"
          : "invisible opacity-0 h-1 pt-0"
      } bg-white bg-opacity-0 lg:bg-opacity-80 lg:backdrop-blur-md lg:absolute lg:rounded-lg lg:w-[500px] lg:top-[8.5rem] lg:pt-8 lg:pb-6 lg:px-16 lg:right-20 lg:shadow-lg lg:drop-shadow-xl`}
    >
      <div className="font-jarkatar font-semibold text-xl mb-4">
        Search results:
      </div>
      {searchResults?.hits.length === 0 && validQuery && (
        <p className="font-normal">No search results were found.</p>
      )}
      {searchResults?.hits.length > 0 && validQuery && (
        <div>
          {searchResults.hits.map((hit, index) => {
            const { title, subPath, image, date } = hit;
            const slugPath = slugify(subPath, { lower: true });
            const pathToImage = getImage(image);
            return (
              <div
                key={index}
                className="my-4 bg-white rounded-md drop-shadow group"
              >
                <Link to={`/${slugPath}/`}>
                  <div className="flex ">
                    <GatsbyImage
                      image={pathToImage}
                      alt={title}
                      className={`${smooth} w-[30%] rounded-l-md object-contain h-28 lg:h-24 group-hover:brightness-[100%] brightness-[90%]`}
                    />
                    <div className="flex flex-col justify-between ml-4 my-3 font-jarkatar w-[70%] font-medium ">
                      <div
                        className={`${smooth} font-bodylao h-14 text-ellipsis overflow-hidden group-hover:text-lime-600`}
                      >
                        {title}
                      </div>
                      <div className="flex justify-start w-full items-center mb-0 mt-2">
                        <div className="h-[2px] w-[25px] bg-stone-200 mr-2 rounded-xl"></div>
                        <div className="font-jarkatar font-base text-xs text-stone-400">
                          {date}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default connectStateResults(CustomHits);
