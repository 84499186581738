import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { IoIosSend } from "@react-icons/all-files/io/IoIosSend";

export default function ResponsePopUp({ title, titleIns, titleInsEng }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <button type="submit" onClick={openModal} className="flex">
          <IoIosSend className="mr-2.5 text-xl mt-0.5" /> {title}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="transition-all duration-300 min-h-screen bg-stone-900 bg-opacity-30 backdrop-blur-md px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex flex-col justify-start items-start text-lg lg:text-xl font-headlao leading-6 text-gray-900"
                >
                  <div className="">{titleIns}</div>
                  <div className="font-jarkatar  text-base lg:text-lg border-t-2 border-stone-300 pt-2 mt-2">
                    {titleInsEng}
                  </div>
                </Dialog.Title>

                <div className="mt-4">
                  <button
                    type="button"
                    className="transition-all duration-300 font-lufga400 inline-flex justify-center px-4 py-2 text-sm font-medium text-stone-600 bg-stone-100 border border-transparent rounded-md hover:text-white hover:bg-green-120"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
