import React from "react";
import { Link } from "gatsby";
import { AiOutlineHeart } from "@react-icons/all-files/ai/AiOutlineHeart";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Form from "./Form";

const smooth = "transition-all ease-in-out duration-300";

const LoadLogoFooter = graphql`
  {
    allFile(filter: { name: { eq: "logo" }, extension: { eq: "jpg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(LoadLogoFooter);
  const image = getImage(data.allFile.nodes[0]);
  return (
    <div
      className={`w-full 2xl:w-[1440px] h-full flex flex-col justify-center items-start pt-6 lg:pt-12`}
    >
      <div className={`px-4 md:px-16 flex flex-col lg:flex-row w-full`}>
        <div
          className={`font-jarkatar w-[100%] lg:w-[60%] flex flex-col justify-start items-start`}
        >
          <div className="w-full flex justify-start items-center font-semibold font-cardo text-xl mb-4 cursor-default">
            Talk with us here
            <AiOutlineHeart className="ml-2 text-2xl" />
          </div>

          <div className=" w-full">
            <Form />
          </div>
        </div>

        <div
          className={`w-full lg:w-[40%] flex justify-center lg:justify-end mt-8 lg:mt-0`}
        >
          <div className="flex flex-col items-center lg:items-end justify-start h-full w-[50%] lg:w-[40%] ">
            <div className="mb-4 font-cardo font-semibold text-xl cursor-default">
              Quick Link
            </div>
            <Link
              to="/"
              className={`${smooth} hover:text-green-120 font-jarkatar font-medium mb-3 text-base`}
            >
              Home
            </Link>
            <Link
              to="/publication/"
              className={`${smooth} hover:text-green-120 font-jarkatar font-medium mb-3 text-base`}
            >
              Publication
            </Link>

            <Link
              to="/about/"
              className={`${smooth} hover:text-green-120 font-jarkatar font-medium mb-3 text-base`}
            >
              About Us
            </Link>
            <Link
              to="/contact/"
              className={`${smooth} hover:text-green-120 font-jarkatar font-medium mb-3 text-base`}
            >
              Contact
            </Link>
          </div>
          <div className="flex flex-col items-center lg:items-end justify-start h-full w-[50%] lg:w-[40%] ">
            <div className="mb-4 font-cardo font-semibold text-xl cursor-default">
              Social Media
            </div>
            <a
              href="https://www.facebook.com/econewslao/"
              className={`${smooth} hover:text-green-120 font-jarkatar font-medium mb-3 text-base`}
            >
              Facebook
            </a>
          </div>
        </div>
      </div>

      <div
        className={`px-4 md:px-16 flex flex-col lg:flex-row justify-center items-center text-base w-full mt-6 lg:mt-16 mb-24 font-cardo text-stone-600`}
      >
        <div className="h-full w-full lg:w-[40%] flex justify-center lg:justify-start items-center">
          <Link to="/" aria-label="linkHome">
            <GatsbyImage
              image={image}
              alt="logo"
              className={`${smooth} w-44 mix-blend-multiply saturate-0 hover:scale-[105%] hover:saturate-100 hover:opacity-100 opacity-[85%]`}
            />
          </Link>
        </div>

        <div className="flex flex-col lg:flex-row text-base w-full lg:w-[60%] mt-4 lg:mt-0">
          <div className="cursor-default w-full lg:w-[50%] flex justify-center lg:justify-end items-center">
            © 2022 Copyright by Econox Laos
          </div>
          <div className="w-full lg:w-[50%] flex mt-2 lg:mt-0">
            <div className="cursor-default w-1/2 lg:w-[60%] flex justify-end lg:justify-end items-center">
              All rights reserved
            </div>
            <div className="cursor-default w-1/2 lg:w-[40%] flex justify-center lg:justify-end items-center">
              Policy
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
