import React, { useState } from "react";
import { Link } from "gatsby";
import { IoCaretBackCircle } from "@react-icons/all-files/io5/IoCaretBackCircle";
import { IoCloseCircle } from "@react-icons/all-files/io5/IoCloseCircle";
// import { BsSearch } from "@react-icons/all-files/bs/BsSearch";
// import ScrollLock from "react-scrolllock";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Search from "./Search";

const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";
const smooth = "transition-all ease-in-out duration-300";

const LoadLogo = graphql`
  {
    allFile(filter: { name: { eq: "logo" }, extension: { eq: "jpg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  }
`;

const Navbar = () => {
  const data = useStaticQuery(LoadLogo);
  const image = getImage(data.allFile.nodes[0]);
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white overflow-hidden h-32 w-screen flex justify-center shadow-md mb-12 rounded-b-2xl">
      <div
        className={`${hover} relative z-10 pl-4 lg:pl-0 flex justify-start lg:justify-center items-center w-1/2 lg:w-1/4 text-3xl font-cardo font-bold cursor-pointer text-green-110`}
      >
        <Link to="/" className="flex justify-center items-center ">
          <GatsbyImage
            image={image}
            alt="logo"
            className={`w-48 mix-blend-multiply`}
          />
        </Link>
      </div>
      <div className="relative z-30 hidden lg:flex justify-center items-center w-2/4">
        <nav className=" flex justify-center items-center space-x-6 font-semibold font-jarkatar w-full h-full">
          <Link
            to="/"
            className={`${hover} flex justify-center items-center px-4 py-2 w-[100px] h-full hover:text-green-110`}
            activeClassName={`text-white bg-green-110 hover:text-green-50`}
          >
            Home
          </Link>
          <Link
            to="/publication/"
            className={`${hover} flex justify-center items-center px-4 py-2 w-[120px] h-full hover:text-green-110`}
            activeClassName={`text-white bg-green-110 hover:text-green-50`}
          >
            Publications
          </Link>

          <Link
            to="/about/"
            className={`${hover} flex justify-center items-center px-4 py-2 w-[100px] h-full hover:text-green-110`}
            activeClassName="text-white bg-green-110 hover:text-green-50"
          >
            About
          </Link>
          <Link
            to="/contact/"
            className={`${hover} flex justify-center items-center px-4 py-2 w-[100px] h-full hover:text-green-110`}
            activeClassName="text-white bg-green-110 hover:text-green-50"
          >
            Contact
          </Link>
        </nav>
      </div>
      <div className="z-50 hidden lg:flex justify-center items-center w-1/4 pr-10">
        <Search />
      </div>
      <div className="relative z-20 pr-4 lg:pr-0 flex lg:hidden justify-end items-center w-1/2">
        <button
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          className={`${smooth} p-3 bg-stone-50 shadow-lg rounded-full text-xl text-green-110 hover:text-green-120`}
        >
          <IoCaretBackCircle />
        </button>
      </div>
      <button
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        aria-label="overlay"
        className={`cursor-e-resize  ${
          open === true
            ? "z-30 bg-gray-800 visible opacity-50 absolute h-full w-screen transition-all ease-in-out duration-300"
            : "z-30 bg-gray-800 invisible opacity-0 absolute h-full w-screen transition-all ease-in-out duration-300 "
        }`}
      ></button>
      <div className="overflow-hidden h-full absolute w-[85%] sm:w-3/5 right-0">
        <div
          className={`transform ${
            open === true
              ? "z-40 relative translate-x-0 transition-all ease-in-out duration-300"
              : "z-40 relative translate-x-full transition-all ease-in-out duration-300 "
          } h-full bg-gray-50 flex flex-col justify-start items-center pt-8 `}
        >
          <div className="flex justify-end w-full pr-8">
            {/* <ScrollLock isActive={open} /> */}
            <button
              onClick={() => setOpen((prevOpen) => !prevOpen)}
              className={`${smooth} p-3 bg-white shadow-lg rounded-full text-xl text-green-110 hover:text-green-120`}
            >
              <IoCloseCircle className="" />
            </button>
          </div>
          <div className="flex flex-col justify-start items-center w-full py-8 space-y-1.5 text-lg font-jarkatar font-semibold">
            <div className="flex lg:hidden justify-center items-center w-[100%] px-8  pb-6">
              <Search />
            </div>
            <Link
              className={`${smooth} flex justify-start w-3/4 hover:text-green-110 hover:bg-green-120 hover:bg-opacity-10 py-3 pl-4 rounded-lg`}
              to="/"
            >
              Home
            </Link>
            <Link
              className={`${smooth} flex justify-start w-3/4 hover:text-green-110 hover:bg-green-120 hover:bg-opacity-10 py-3 pl-4 rounded-lg`}
              to="/publication/"
            >
              Publication
            </Link>
            <Link
              className={`${smooth} flex justify-start w-3/4 hover:text-green-110 hover:bg-green-120 hover:bg-opacity-10 py-3 pl-4 rounded-lg`}
              to="/about/"
            >
              About
            </Link>
            <Link
              className={`${smooth} flex justify-start w-3/4 hover:text-green-110 hover:bg-green-120 hover:bg-opacity-10 py-3 pl-4 rounded-lg`}
              to="/contact/"
            >
              Contact
            </Link>
            <div className="border-t-2 border-stone-300 w-3/4 pt-4 font-normal text-xs flex justify-center font-jarkatar">
              © All rights reserved to Econox Laos, 2022
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
