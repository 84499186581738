import React from "react";
import { useState } from "react";
import { send } from "emailjs-com";
import ResponsePopUp from "./ResponsePopUp";
const smooth = "transition-all ease-in-out duration-300";

const Form = () => {
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  });
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setToSend({
      ...toSend,
      from_name: "",
      to_name: "",
      message: "",
      reply_to: "",
    });
    send(
      `${process.env.GATSBY_EMAILJS_SERVICE_ID}`,
      `${process.env.GATSBY_EMAILJS_TEMPLATE_ID}`,
      toSend,
      `${process.env.GATSBY_EMAILJS_USER_ID}`
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <form
      onSubmit={onSubmit}
      className={`${smooth} flex flex-col justify-center items-center`}
    >
      <div className="flex flex-col lg:flex-row justify-center items-center w-full">
        <div className="flex flex-col w-full lg:w-1/2 mb-3 lg:mb-0">
          <input
            type="text"
            name="from_name"
            placeholder="Fullname"
            required
            className={`${smooth} text-base focus:bg-white bg-stone-200/40 mr-0 lg:mr-4 mb-1 px-4 py-1.5 outline-0 font-jarkatar font-medium`}
            value={toSend.from_name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="reply_to"
            placeholder="Email"
            required
            className={`${smooth} text-base focus:bg-white bg-stone-200/40 mr-0 lg:mr-4 my-1 px-4 py-1.5 outline-0 font-jarkatar font-medium`}
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <input
            type="text"
            name="to_name"
            placeholder="Subject"
            required
            className={`${smooth} text-base focus:bg-white bg-stone-200/40 mr-0 lg:mr-4 my-1 px-4 py-1.5 outline-0 font-jarkatar font-medium`}
            value={toSend.to_name}
            onChange={handleChange}
          />
        </div>

        <div className="h-full w-full lg:w-1/2 ">
          <textarea
            name="message"
            placeholder="Your message"
            required
            className={`${smooth} h-[8rem] w-full text-base focus:bg-white bg-stone-200/40 px-4 py-2 outline-0 font-jarkatar font-medium`}
            value={toSend.message}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={`${smooth} mt-3 text-base w-full flex justify-end`}>
        <div
          role="button"
          className={`${smooth} hover:-translate-y-0.5 cursor-pointer hover:text-green-120 flex font-jarkatar font-medium bg-white px-4 py-1.5 drop-shadow-md shadow shadow-stone-300 rounded-md`}
        >
          <ResponsePopUp
            title={"Send"}
            titleIns={"ຂອບໃຈ, ແລ້ວເຮົາຈະຕິດຕໍ່ກັບໄວໆນີ້"}
            titleInsEng={"Thank you, we will get in touch with you shortly"}
          />
        </div>
      </div>
    </form>
  );
};

export default Form;
