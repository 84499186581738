import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// import metaImage from "../images/logo.jpg";

const getDetails = graphql`
  {
    site {
      siteMetadata {
        title
        description
        siteUrl
        keywords
        defaultImage: image
      }
    }
  }
`;

const Seo = ({ title, description, image, pathname }) => {
  const { site } = useStaticQuery(getDetails);
  const metaDescription = description || site.siteMetadata.description;
  const keywords = site.siteMetadata.keywords;
  const siteUrl = site.siteMetadata.siteUrl;
  const canonical = `${siteUrl}/${pathname}`;
  // const {defaultImage } = site.siteMetadata
  const siteImage = image;
  // const seo = {image: `${siteUrl}${image || defaultImage}`,}

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`${title} | ${site.siteMetadata.title}`}
      meta={[
        { name: `description`, content: metaDescription },
        { name: `keywords`, content: keywords },
        { property: `og:title`, content: title },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { property: `og:url`, content: canonical },
        { property: `og:image:url`, content: siteImage },
        { name: `twitter:image`, content: siteImage },
        { name: `twitter:title`, content: title },
        { name: `twitter:description`, content: metaDescription },
      ]}
    >
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};

Seo.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  image: PropTypes.string,
};

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  meta: null,
};

export default Seo;
